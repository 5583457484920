import { render, staticRenderFns } from "./WarehouseSetting.vue?vue&type=template&id=bc4c285c&scoped=true&"
import script from "./WarehouseSetting.vue?vue&type=script&lang=js&"
export * from "./WarehouseSetting.vue?vue&type=script&lang=js&"
import style0 from "./WarehouseSetting.vue?vue&type=style&index=0&id=bc4c285c&scoped=true&lang=css&"
import style1 from "./WarehouseSetting.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc4c285c",
  null
  
)

export default component.exports