import { render, staticRenderFns } from "./FlowCategorySetting.vue?vue&type=template&id=fc899f14&scoped=true&"
import script from "./FlowCategorySetting.vue?vue&type=script&lang=js&"
export * from "./FlowCategorySetting.vue?vue&type=script&lang=js&"
import style0 from "./FlowCategorySetting.vue?vue&type=style&index=0&id=fc899f14&scoped=true&lang=css&"
import style1 from "./FlowCategorySetting.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc899f14",
  null
  
)

export default component.exports